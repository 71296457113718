html, body {
  font-family: Roboto, Muli, Helvetica Neue, Arial, sans-serif;
  background-color: white;
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  padding: 0;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.carousel-control-prev {
  width: 10%;
  height: 30%;
  margin-top: 260px;
  margin-left: 40%;
}

.carousel-control-next {
  width: 10%;
  height: 30%;
  margin-top: 260px;
  margin-right: 40%;
}

* {
  box-sizing: border-box;
}

button.title {
  display: none;
}

.apexcharts-toolbar {
  max-width: unset!important;
}
